import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (this.hasOathModalTarget) {
      this.initializeOathModal();
    }

    if (this.hasNewOathTypeSelectTarget) {
      this.setupNewOathTypeSelect();
    }

    if (this.hasNewOathSignatureSelectTarget) {
      this.setupNewOathSignatureSelect();
    }

    if (this.hasEditOathSignatureSelectTarget) {
      this.setupEditOathSignatureSelect();
    }
  }

  initializeOathModal() {
    let current_instructions, hideOathToggle, refuseOathToggle, value;
    epulseModal.form(this.oathModalTarget, {
      sortable: true,
      onShow: (origin) => {
        current_instructions = parseInt(current_instructions);
        this.hideSignatureInstructions(current_instructions);
        this.updateOathContent(origin);
        hideOathToggle   = this.toggleHideOathPage(origin);
        refuseOathToggle = this.toggleRefuseOathPage(origin);
      },
    });
  }

  hideSignatureInstructions() {
    const currentInstructions        = parseInt(current_instructions);
    const editSignaturesInstructions = document.querySelectorAll(".edit_signatures_1_instructions");

    if (currentInstructions > 0) {
      editSignaturesInstructions.forEach(element => {
      element.style.display = "block";
    });
    } else {
      editSignaturesInstructions.forEach(element => {
      element.style.display = "none";
    });
    }
  }

  updateOathContent(origin) {
    const originElement        = document.querySelector(origin);
    const oathContentContainer = originElement.querySelector('.oath-content-container');
    const value                = oathContentContainer.innerHTML.trim();
    const accountOathOath      = document.getElementById('account_oath_oath');
    
    accountOathOath.innerHTML  = value;
  }

  toggleHideOathPage(origin) {
    const originElement  = document.querySelector(origin);
    const hideOathToggle = originElement.getAttribute('data-hide-oath-page') === 'true' || false;
    
    document.getElementById("account-oath-edit-hide-oath-page").checked = hideOathToggle;
  }

  toggleRefuseOathPage(origin) {
    const originElement    = document.querySelector(origin);
    const refuseOathToggle = originElement.getAttribute('data-refuse-oath-page') === 'true' || false;
    
    document.getElementById("account-oath-edit-refuse-oath-page").checked = refuseOathToggle;
  }

  setupNewOathTypeSelect() {
    this.newOathTypeSelectTarget.addEventListener("change", () => {
      this.toggleRefuseOathPageForNewOath(this.originTarget);
    });
  }

  toggleRefuseOathPageForNewOath(origin) {
    const refuseOathToggle = this.toggleRefuseOathPage(origin); 

    document.getElementById("account-oath-edit-refuse-oath-page").checked = refuseOathToggle;

    const dataAttributeType = originElement.getAttribute('data-type');

    const labelForRefuseOath = document.querySelector('label[for="refuse_oath"]');

    const slideAccountOathEditRefuseOathPage = document.getElementById("slide-account-oath-edit-refuse-oath-page");

    if (dataAttributeType === "assistance" || dataAttributeType === "interpreter") {
      labelForRefuseOath.style.display = 'block';
      slideAccountOathEditRefuseOathPage.style.display = 'block';
    } else {
      labelForRefuseOath.style.display = 'none';
      slideAccountOathEditRefuseOathPage.style.display = 'none';
    }
  }

  setupNewOathSignatureSelect() {
    this.newOathSignatureSelectTarget.addEventListener("change", () => {
      this.toggleSignatureInstructionsForNewOath();
    });
  }

  toggleSignatureInstructionsForNewOath() {
    const newOathSignatureSelect = document.getElementById("new-oath-signature-select");

    newOathSignatureSelect.addEventListener("change", function(e) {
      const signatures_count = this.value;
      const signatureInstructions = document.querySelectorAll(".signature_1_instructions");

      signatureInstructions.forEach(function(element) {
        if (signatures_count === "1") {
          element.style.display = "block";
        } else {
          element.style.display = "none";
        }
      });
    });

    newOathSignatureSelect.dispatchEvent(new Event("change"));
  }

  setupEditOathSignatureSelect() {
    this.editOathSignatureSelectTarget.addEventListener("change", () => {
      this.toggleSignatureInstructionsForEditOath();
    });
  }

  toggleSignatureInstructionsForEditOath() {
    const editOathSignatureSelect = document.getElementById("edit-oath-signature-select");

    editOathSignatureSelect.addEventListener("change", function(e) {
      const signatures_count           = this.value;
      const editSignaturesInstructions = document.querySelectorAll(".edit_signatures_1_instructions");

      editSignaturesInstructions.forEach(function(element) {
        if (signatures_count === "1") {
          element.style.display = "block";
        } else {
          element.style.display = "none";
        }
      });
    });

    editOathSignatureSelect.dispatchEvent(new Event("change"));
  }

  static targets = [
    "oathModal",
    "newOathTypeSelect",
    "newOathSignatureSelect",
    "editOathSignatureSelect",
    "origin",
  ];
}
