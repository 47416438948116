// webpack dependencies
import DOMPurify from "dompurify";
window.DOMPurify = DOMPurify
import "../concerns/navMenuUtilities";
import "../concerns/pollingPlaceTwilio";
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import Flipper from 'flipper-rb';

const application = Application.start()

window.Stimulus = Application.start()
const context = require.context("../controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application
window.Flipper    = Flipper