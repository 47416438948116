// WIP prototype on a new class based, non jQuery modal we need for stimulus. We need to take all the modal code out of epulseModal function
// and put it in this class. We need to de-jqueryize those methods calls using modern 'vanilla js' for modern browsers best practices.
// Then we need to replace all usages of epulseModal in stimulusJS controllers with this class.
class EpulseModal {
    static show(id) {
      this.showModal(id);
    }
  
    static close() {
      this.closeModal();
    }
  
    static showModal(id, origin) {
      let state = document.querySelector(id + ' .modal-state');
      state.dataset.openOrigin = origin;
      state.checked = true;
      state.dispatchEvent(new Event('change'));
    }
  
    static closeModal(origin) {
      let states = document.querySelectorAll('.modal-state:checked');
      states.forEach(state => {
        state.dataset.closeOrigin = origin;
        state.checked = false;
        state.dispatchEvent(new Event('change'));
      });
    }

    static modalForm(id, options = {}) {
        const modal = document.querySelector(id);
        if (!modal) {
          return;
        }
    
        const form = modal.querySelector('form');
        if (!form) {
          return;
        }
    
        modal.addEventListener('modal:open', function(e) {
          const origin = e.detail.origin;
    
          if (options.fields) {
            const values = options.fields;
            Object.keys(values).forEach(function(key) {
              const field = modal.querySelector(key);
              const val = origin.dataset[values[key]];
    
              if (field.type === 'checkbox') {
                field.checked = val;
              } else if (field.tagName.toLowerCase() === 'input') {
                field.value = val;
              } else if (field.tagName.toLowerCase() === 'select') {
                if (typeof val === "boolean") {
                  field.value = val.toString();
                } else if (field.multiple && typeof val === 'string') {
                  field.value = val.split(',');
                }
    
                if (field.classList.contains('chosen-select')) {
                  // TODO: trigger "chosen:updated" event
                }
              } else {
                field.innerHTML = val;
              }
            });
          }
    
          if (options.multi) {
            // requires a parseBulkIds() method
            document.querySelector(options.multi).value = this.parseBulkIds();  
          }
    
          if (options.onShow) {
            options.onShow(origin);
          }
    
          if (options.remoteData) {
            // requires a processRemoteData() method
            this.processRemoteData(modal, origin, options);
          }
        });
    
      }
  
}
