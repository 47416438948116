import { Controller } from "@hotwired/stimulus";

import EpulseModal from '../concerns/epulseModal.js'; 

export default class extends Controller {
  static targets = [
    "assignModal",
    "bulkDeleteModal",
    "editModal",
    "precinctModal",
    "pushSync",
    "accountSelect",
    "accountElectionsSelect",
    "pollPadsImportAdminForm",
    "exportPollPadsLink"
  ];

  connect() {
    this.initializeForms();
  }

  initializeForms() {
    epulseModal.form(this.assignModalTarget, { /* options */ });
    epulseModal.form(this.bulkDeleteModalTarget, { /* options */ });
    epulseModal.form(this.editModalTarget, { /* options */ });
    epulseModal.form(this.precinctModalTarget, { /* options */ });
  }

  onPushSyncClick(event) {
    event.preventDefault();
    let ids = epulseModal.bulkIds();
    if (Array.isArray(ids)) {
      ids = ids.join(",");
    }

    fetch("/poll_pads/sync", {
      method: "POST",
      body: new URLSearchParams({ ids: ids }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    })
      .then(response => response.json())
      .then(data => {
        document.querySelector("#page_alerts").innerHTML = data.alerts;
      })
      .catch(error => {
        epulse.showError();
      });
  }

  onSelectChange(event) {
    const form = event.currentTarget.closest('form');
    const accountId = form.querySelector('.poll-pads-account-select').value;

    if (accountId && accountId.length > 0) {
      const pollPadId = form.querySelector('#poll_pad_id').value;
      const electionId = form.querySelector('.poll-pads-account-elections-select').value;

      fetch("/admin/poll_pads/assign_info", {
        method: "POST",
        body: new URLSearchParams({
          id: accountId,
          poll_pad_id: pollPadId,
          election_id: electionId
        }),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        }
      })
        .then(response => response.json())
        .then(data => {
          this.updateAccountSelects(form, data);
        });
    } else {
      this.updateAccountSelects(form, { configs: "", places: "", elections: "" });
    }
  }

  onImportAdminFormSubmit(event) {
    this.pollPadsImportAdminFormTarget.querySelector('input[type=submit]').style.display = 'none';
    document.querySelector('.progress-info').style.display = 'block';
  }

  onExportPollPadsClick(event) {
    const data = Object.assign({ format: 'csv' }, epulseSort.sortedParams());
    this.exportPollPadsLinkTarget.setAttribute("href", `poll_pads/assignments?${DOMPurify.sanitize(new URLSearchParams(data).toString())}`);
  }

  updateAccountSelects(form, data) {
    form.querySelector('.poll-pads-account-configs-select').innerHTML = data.configs;
    form.querySelector('.poll-pads-account-places-select').innerHTML = data.places;
    form.querySelector('.poll-pads-account-elections-select').innerHTML = data.elections;
  }
}
