import { epulseVideo } from "../concerns/epulseVideo";

// POLLING PLACE VIDEO FEED SETUP
let video = null;

$(function() {
    if ($('#video-messaging').length > 0) {
        return setupVideo();
    } else if ($("#video-full-screen").length > 0) {
        return setupFullScreenVideo();
    }
});

let setupFullScreenVideo = function() {
    video = new epulseVideo($("#video-full-screen"), $("#video-local-preview"), $("#video-full-screen").data('token'), { fullScreen: true });

    $('.video-control-buttons .icn-camera').click(function() {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            return video.disableLocalPreview();
        } else {
            $(this).addClass('active');
            return video.enableLocalPreview();
        }
    });


    $('.video-control-buttons .icn-phone-hangup').click(() => video.leaveRoomAndCloseWindowIfJoined());

    return $('.video-control-buttons .icn-mic-mute').click(function() {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            return video.unmute();
        } else {
            $(this).addClass('active');
            return video.mute();
        }
    });
};

let setupVideo = function() {
    $('#polling-place-video').click(function(e) {
        e.stopPropagation();
        e.preventDefault();
        if (video) {
            video.leaveRoomIfJoined();
        }
        const width = window.innerWidth * 0.66;
        const height = width * 0.66;
        return window.open(this.href, 'newwindow', 'width=' + width + ', height=' + height + ', top=' +
            ((window.innerHeight - height) / 2) + ', left=' + ((window.innerWidth - width) / 2));
    });

    $('#polling-place-video-full').click(function(e) {
        e.stopPropagation();
        e.preventDefault();
        if (video) {
            video.leaveRoomIfJoined();
        }
        const width = window.innerWidth * 0.66;
        const height = width * 0.66;
        return window.open(this.href, 'newwindow', 'width=' + width + ', height=' + height + ', top=' +
            ((window.innerHeight - height) / 2) + ', left=' + ((window.innerWidth - width) / 2));
    });

    if ($('#video-messaging').data('auto') === true) {
        return startVideo("Initializing...");
    }
};

let startVideo = function(message) {
    if (!video) {
        updateVideoStatus(message);
        $.ajax('/messages/video_token', {
            type: 'POST',
            dataType: 'json',
            data: { place_id: $('#video-messaging').data('place') },
            success(data, status, xhr) {
                const token = data['token'];
                return video = new epulseVideo($("#polling-place-video"), null, token);
            },

            error(xhr, status, error) {
                return updateVideoStatus('Video Error');
            }
        }
        );

        return true;
    }
    return false;
};

let updateVideoStatus = status => $('#polling-place-video-status').html(status);

