import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]

  connect() {
    this.setupModalObserver()
  }

  setupModalObserver() {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach((node) => {
            if (node.classList && (node.classList.contains('sync-api-error-msg-modal') || node.classList.contains('sync-api-info-modal'))) {
              this.setupModalClose(node)
            }
          })
        }
      })
    })

    observer.observe(document.body, { childList: true, subtree: true })
  }

  setupModalClose(modal) {
    const overlay = modal.querySelector('.sync-api-error-msg-modal-overlay, .sync-api-info-modal-overlay')
    if (overlay) {
      overlay.addEventListener('click', this.closeModal.bind(this))
    }
    modal.addEventListener('click', this.handleModalClick.bind(this))
  }

  handleModalClick(event) {
    if (event.target.closest('.sync-api-info-modal-wrapper, .sync-api-error-msg-modal-wrapper')) {
      return
    }
    this.closeModal(event)
  }

  closeModal(event) {
    event.preventDefault()
    event.stopPropagation()
    const modal = event.target.closest('.sync-api-info-modal, .sync-api-error-msg-modal')
    
    if (modal && !modal.dataset.closing) {
      modal.dataset.closing = 'true'
      const modalContent = modal.querySelector('.sync-api-info-modal-transition, .sync-api-error-msg-modal-transition')
      const overlay = modal.querySelector('.sync-api-info-modal-overlay, .sync-api-error-msg-modal-overlay')
      
      
      if (modalContent && overlay) {
        modalContent.style.transform = 'translateY(-10%)'
        modalContent.style.opacity = '0'
        overlay.style.opacity = '0'
        
        setTimeout(() => {
          modal.remove()
        }, 300)
      } else {
        modal.remove()
      }
    } else {
    }
  }

  closeAllModals() {
    document.querySelectorAll('.sync-api-info-modal, .sync-api-error-msg-modal').forEach(modal => {
      if (!modal.dataset.closing) {
        const event = new Event('click')
        modal.dispatchEvent(event)
      }
    })
  }

  copyToClipboard(event) {
    const errorMessage = this.errorMessageTarget.textContent
    navigator.clipboard.writeText(errorMessage).then(() => {
    }).catch(err => {
      console.error('Failed to copy text: ', err)
    })
  }
}