const Video = require('twilio-video');

// Twilio Video Builder Class
class epulseVideo {
    constructor(videoContainer, localMediaContainer, token, options) {
        this.videoContainer = videoContainer;
        this.localMediaContainer = localMediaContainer;
        this.token = token;
        this.options = options;
        this.options = (this.options != null) ? this.options : {};
        this.localMediaContainer = (this.localMediaContainer != null) ? this.localMediaContainer : [];
        this.activeRoom = null;
        if (this.videoContainer.length > 0) {
            this.updateStatus("Initializing Video...");

            if (!navigator.webkitGetUserMedia && !navigator.mozGetUserMedia) {
                alert('Video will be unavailable: WebRTC is not available in your browser.');
            }
            window.addEventListener('beforeunload', this.leaveRoomIfJoined);

            if (this.options.fullScreen) {
                $(window).resize(this.setVideoHeight.bind(this));
            }

            const windowDimensions = { width: this.videoContainer.width() };
            Video.connect(this.token, { video: windowDimensions, audio: true }).then(
                this.roomJoined.bind(this),
                err => {
                    if (err && (err.name === "NotAllowedError")) {
                        return this.updateStatus("Waiting For Access to Camera and Microphone").bind(this);
                    } else {
                        return this.updateStatus("Video Error").bind(this);
                    }
                }
            );
        }
    }

    roomJoined(room) {
        this.activeRoom = room;
        this.updateStatus("Waiting for PollPad");
        this.checkParticipants();
        const self = this;
        room.participants.forEach(participant => attachParticipantTracks(participant, self.videoContainer));

        room.on('trackSubscribed', (track, participant) => attachTracks([track], self.videoContainer));

        room.on('trackUnsubscribed', (track, participant) => detachTracks([track]));

        room.on('participantDisconnected', function(participant) {
            detachParticipantTracks(participant);
            return self.checkParticipants();
        });

        room.on('participantConnected', participant => self.checkParticipants());

        return room.on('disconnected', function(room) {
            detachParticipantTracks(room.localParticipant);
            self.activeRoom = null;
            self.setStatusHidden(false);
            const msg = (self.options.fullScreen != null) ? 'Call Ended' : 'Click to Start';
            return self.updateStatus(msg);
        });
    }

    leaveRoomIfJoined() {
        if (this.activeRoom) {
            return this.activeRoom.disconnect() && (this.activeRoom = null);
        }
    }

    leaveRoomAndCloseWindowIfJoined() {
        if (this.activeRoom) {
            return this.activeRoom.disconnect() && (window.close());
        }
    }

    mute() {
        if (this.activeRoom) {
            const localUser = this.activeRoom.localParticipant;
            getTracks(localUser).forEach(function(track) {
                if (track.kind === 'audio') {
                    track.disable();
                }
            });
        }
    }

    unmute() {
        if (this.activeRoom) {
            const localUser = this.activeRoom.localParticipant;
            getTracks(localUser).forEach(function(track) {
                if (track.kind === 'audio') {
                    track.enable();
                }
            });
        }
    }

    enableLocalPreview() {
        if ((this.localMediaContainer.length > 0) && this.activeRoom) {
            this.localMediaContainer.show();
            return attachParticipantTracks(this.activeRoom.localParticipant, this.localMediaContainer);
        }
    }

    disableLocalPreview() {
        if ((this.localMediaContainer.length > 0) && this.activeRoom) {
            this.localMediaContainer.hide();
            return detachParticipantTracks(this.activeRoom.localParticipant);
        }
    }

    updateStatus(status) {
        if (this.options.fullScreen) {
            return $('#full-screen-video-status').html(status);
        } else {
            return $('#polling-place-video-status').html(status);
        }
    }

    setStatusHidden(hidden) {
        const status = this.options.fullScreen ? $('#full-screen-video-status') : this.videoContainer.find('.video-text');
        if (hidden) {
            return status.hide();
        } else {
            return status.show();
        }
    }

    checkParticipants() {
        return this.setStatusHidden(this.activeRoom && (this.activeRoom.participants.size > 0));
    }

    setVideoHeight() {
        const videoRatio = this.videoContainer.height() / this.videoContainer.width();

        const height = videoRatio * window.innerWidth;
        const width = window.innerHeight / videoRatio;

        if (height > window.innerHeight) {
            this.videoContainer.height(window.innerHeight - 5);
            return this.videoContainer.width('auto');
        } else {
            this.videoContainer.width(window.innerWidth);
            return this.videoContainer.height('auto');
        }
    }
};

//private helper methods
let attachTracks = (tracks, container) => tracks.forEach(track => container.append(track.attach()));

let attachParticipantTracks = function(participant, container) {
    const tracks = getTracks(participant);
    return attachTracks(tracks, container);
};

function detachParticipantTracks(participant) {
    let tracks = getTracks(participant);
    detachTracks(tracks);
}

let detachTracks = function(tracks) {
    for (let track of tracks) {
        if (track.kind !== 'data') {
            const htmlElements = track.detach();
            for (let htmlElement of htmlElements) {
                htmlElement.remove();
            }
        }
    }
}

let getTracks = function(participant) {
    return Array.from(participant.tracks.values()).filter(function(publication) {
        return publication.track;
    }).map(function(publication) {
        return publication.track;
    });
}


export { epulseVideo }

